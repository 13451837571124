.c-loop-opinion {
  padding: 30px;
  border-radius: 30px;
  background: #fff;
  display: flex;
  align-items: center;

  @include bp-down(medium) {
    flex-wrap: wrap;
    padding: 15px;
  }

  &__left {
    width: 200px;

    @include bp-down(medium) {
      width: auto;
    }
  }

  &__right {
    width: calc(100% - 200px);
    padding-left: 30px;

    @include bp-down(medium) {
      width: 100%;
      padding-left: 0;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 21px;

    @include bp-down(medium) {
      margin-bottom: 15px;
    }
  }

  &__image {
    width: 200px;
    height: auto;
    max-height: 100%;

    @include bp-down(medium) {
      width: auto;
      height: 200px;
      margin-bottom: 15px;
    }
  }

  &__avatar {
    width: 61px;
    height: auto;
    margin-right: 15px;
  }

  &__client {
    font-weight: 700;
  }
  //   &__company {
  //   }
  &__opinion {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 21px;
  }

  &__product-name {
    color: #6b6c6e;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  &__product-sub-name {
    margin-top: 3px;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  &__description {
    margin-top: 5px;
    font-size: 14px;
    line-height: 16px;
  }
}
