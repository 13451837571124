@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import '../node_modules/bootstrap/scss/bootstrap-utilities';

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;

  > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1170px;
  }
}
