/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: #000;
  text-decoration: underline;

  &:hover {
    color: #000;
  }
}
