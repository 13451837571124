.s-instagram {
  padding: 53px 0 57px 0;
  background: $tolpa-peach;
  overflow: hidden;

  &__container {
    &--swiper {
      padding: 0;
    }
  }

  &__swiper {
    @include bp-down(medium) {
      overflow: visible;
      margin: 0 15px;
    }
  }

  &__header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bp-down(medium) {
      margin-bottom: 27px;
    }
  }

  &__title {
    margin: 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
  }

  &__cta {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;

    &--mobile {
      display: none;

      @include bp-down(medium) {
        display: block;
        margin-top: 10px;
      }
    }

    &--desktop {
      display: block;

      @include bp-down(medium) {
        display: none;
      }
    }

    a {
      color: $tolpa-orange;
      text-decoration: none;

      &:hover {
        color: #000;
      }
    }
  }

  &__arrows {
    display: none;

    @include bp-down(medium) {
      display: block;
    }
  }

  &__arrow {
    background: #fff;
    width: 56px;
    height: 56px;
    border-radius: 70px;
    border: 0;
    margin-left: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  iframe {
    width: 100%;
    min-width: auto !important;
  }
}
