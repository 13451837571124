.s-about {
  padding: 80px 0;
  background: #ffefdf;
  font-size: 18px;
  line-height: 24px;

  @include bp-down(medium) {
    padding: 40px 0;
    font-size: 16px;
    line-height: 21px;
  }

  &__col {
    &--box {
      margin-top: 60px;
    }
  }

  &__title {
    margin: 0 0 65px 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }

  &__box {
    position: relative;
    padding: 60px;
    border: 3px dashed #ec792b;
    border-radius: 30px;

    @include bp-down(medium) {
      padding: 60px 15px 40px 15px;
    }

    &--smaller {
      padding: 50px 20px 20px 20px;

      @include bp-down(medium) {
        padding: 40px 15px 20px 20px;
        text-align: center;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  &__box-header-container {
    position: absolute;
    top: -24px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__box-header {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px 10px 50px;
    background: #c9db43;
    border-radius: 30px;
    font-size: 18px;
    line-height: 24px;

    @include bp-down(medium) {
      font-size: 14px;
      line-height: 18px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
  }

  &__top-text {
    margin-bottom: 90px;
    text-align: center;

    @include bp-down(medium) {
      margin-bottom: 30px;
    }
  }

  &__single {
    max-width: 80%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp-down(large) {
      max-width: none;
    }

    @include bp-down(medium) {
      flex-wrap: wrap;
      margin: 25px 0;
      text-align: center;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--reversed {
      flex-direction: row-reverse;
      text-align: right;

      @include bp-down(medium) {
        text-align: center;
      }
    }

    img {
      margin-right: 60px;
      max-width: 300px;
      height: auto;

      @include bp-down(large) {
        max-width: 200px;
      }

      @include bp-down(medium) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
