.s-opinions {
  background: #f0c793;
  overflow: hidden;
  padding: 65px 0 97px 0;

  &__container {
    padding: 0;

    &--header {
      padding: 0 15px;
      margin-bottom: 65px;

      @include bp-down(medium) {
        margin-bottom: 30px;
      }
    }

    &--swiper {
      position: relative;
    }
  }

  &__title {
    margin: 0 0 8px 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }

  &__sub-title {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__swiper {
    overflow: visible;
    margin: 0 100px;
    position: static;

    @include bp-down(medium) {
      margin: 0 15px;
    }
  }

  .swiper-slide {
    &:not(.swiper-slide-active) {
      opacity: 0.5;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    height: 70px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: #fff;
    z-index: 999;
    cursor: pointer;

    @include bp-down(medium) {
      display: none;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
