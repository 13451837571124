html {
  color: #000;
  font-family: $font-sans-serif;
  font-size: 18px; /* [1] */ /* 13px */
  line-height: 24px; /* [1] */
  min-height: 100%;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include bp-down(medium) {
    font-size: 16px;
    line-height: 21px;
  }
}
