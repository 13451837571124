.c-single-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 21px;

  @include bp-down(medium) {
    margin-bottom: 15px;
  }
  &__avatar {
    width: 61px;
    height: auto;
    margin-right: 15px;
  }

  &__client {
    font-weight: 700;
  }
}
