.c-loop-product {
  $root: &;

  display: block;
  padding: 30px;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 20px;

  &:hover {
    border: 1px solid #000;
    #{$root}__link {
      background: #000;
      color: #fff;
    }
  }

  &__thumbnail {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    @include bp-down(medium) {
      display: block;
      height: 300px;
      width: auto;
      margin: 0 auto 12px auto;
    }
  }

  &__post-title {
    font-family: 'Glypha LT Pro', sans-serif;
    color: #6b6c6e;
    font-size: 19px;
    line-height: 19px;
  }

  &__sub-title {
    margin-top: 3px;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 19px;
    line-height: 19px;
  }

  &__description {
    min-height: 32px;
    margin-top: 6px;
    font-size: 14px;
    line-height: 16px;
  }

  &__prices {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price-before {
    font-size: 18px;
    line-height: 20px;
    text-decoration: line-through;
  }

  &__price-after {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
  }

  &__link {
    width: 100%;
    text-align: center;
  }
}
