.s-event-finish {
  background: #f5e0cc;
  overflow: hidden;
  position: relative;
  min-height: 680px;
  height: 100vh;
  @include bp-down(medium) {
    height: auto;
    padding: 150px 0 60px 32px;
  }
  &__logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__box {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include bp-down(medium) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      position: static;
      transform: none;
      align-items: flex-start;
    }
  }
  &__image {
    width: 45%;
    @include bp-down(medium) {
      width: 100%;
    }
  }
  &__content {
    margin-left: 50px;
    @include bp-down(medium) {
      margin-left: 0;
    }
  }
  &__text {
    font-family: 'Palatino';
    &--first {
      color: #d14c4c;
      font-size: 64px;
      font-weight: 700;
      line-height: 64px;
      @include bp-down(medium) {
        font-size: 48px;
        line-height: 48px;
      }
    }
    &--second {
      margin-bottom: 32px;
      color: #000000;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      @include bp-down(medium) {
        margin-bottom: 16px;
        font-size: 25px;
        line-height: 31px;
      }
    }
    &--third {
      color: #000000;
      font-size: 25px;
      font-weight: 700;
      line-height: 31px;
      @include bp-down(medium) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &__fruit {
    position: absolute;
    &--first {
      width: 507px;
      height: auto;
      right: 60px;
      top: -30px;
      @include bp-down(xlarge) {
        width: 350px;
      }
      @include bp-down(large) {
        width: 250px;
      }
      @include bp-down(medium) {
        top: 100px;
        width: 200px;
        right: -30px;
      }
    }
    &--second {
      width: 301px;
      height: auto;
      right: 60px;
      bottom: 0;
      @include bp-down(xlarge) {
        width: 250px;
      }
      @include bp-down(large) {
        width: 200px;
      }
      @include bp-down(medium) {
        display: none;
      }
    }
  }
}
