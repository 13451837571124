.s-power {
  padding: 80px 0;

  @include bp-down(medium) {
    padding: 50px 0;
  }

  &__title {
    margin: 0 0 20px 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 26px;
    line-height: 31px;
  }

  &__description {
    margin-bottom: 60px;

    @include bp-down(medium) {
      margin-bottom: 40px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 75px;
      margin-bottom: 30px;

      @include bp-down(medium) {
        padding-left: 60px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__list-image {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #c9db43;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp-down(medium) {
      width: 45px;
      height: 45px;

      img {
        height: 25px;
      }
    }
  }

  &__image {
    &--first {
      margin-top: -30%;

      @include bp-down(medium) {
        display: none;
      }
    }

    &--second {
      display: block;
      width: 80%;
      margin: 0 0 0 auto;

      @include bp-down(medium) {
        width: 50%;
        margin: 0 0 30px 0;
      }
    }
  }

  &__video {
    iframe {
      width: 100%;
      height: 340px;

      @include bp-down(medium) {
        height: 280px;
      }
    }
    &--first {
      margin-top: -20%;
      @include bp-down(medium) {
        margin-top: 40px;
      }
    }
    &--second {
      @include bp-down(medium) {
        margin-top: 0;
      }
    }
  }

  &__user {
    margin-top: 30px;
    @include bp-down(medium) {
      margin: 20px 0;
    }
    &--first {
      @include bp-down(medium) {
        margin-bottom: 0;
      }
    }
  }
  &__row {
    margin-top: 50px;

    @include bp-down(medium) {
      margin-top: 30px;
    }

    &--center {
      display: flex;
      align-items: center;
    }
  }
}
