.c-btn {
  display: inline-block;
  padding: 16px 24px;
  background: #000;
  color: #fff;
  font-family: 'Glypha LT Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  border: 1px solid #000;

  &:hover {
    background: transparent;
    color: #000;
  }

  &--full {
    background: #000;
    border: 1px solid #000;
    color: #fff;

    &:hover {
      background: transparent;
      color: #000;
    }
  }

  &--bordered {
    background: transparent;
    border: 1px solid #000;
    color: #000;

    &:hover {
      background: #000;
      color: #fff;
    }
  }
}
