.s-products {
  padding: 93px 0 104px 0;

  @include bp-down(medium) {
    padding: 45px 0 50px 0;
  }

  &__col {
    &--product {
      margin-bottom: 30px;

      @include bp-down(medium) {
        margin-bottom: 15px;
      }
    }
  }

  &__title {
    margin: 0 0 8px 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }

  &__description {
    margin-bottom: 50px;
    text-align: center;
    @include bp-down(medium) {
      margin-bottom: 30px;
    }
  }

  &__nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 61px;
    border-radius: 24px;

    @include bp-down(medium) {
      margin-bottom: 30px;
    }
  }

  &__nav-tab {
    margin: 0;
    display: inline-block;
    padding: 16px 24px;
    border-right: 1px solid $tolpa-orange;
    border-top: 1px solid $tolpa-orange;
    border-bottom: 1px solid $tolpa-orange;
    color: #000;
    font-size: 16px;
    font-family: 'Glypha LT Pro', sans-serif;
    line-height: 16px;
    text-decoration: none;

    @include bp-down(medium) {
      padding: 16px;
    }

    &:first-child {
      border-left: 1px solid $tolpa-orange;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &:last-child {
      border-left: 0;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  &__nav-tab.active {
    color: #fff;
    background: $tolpa-orange;
  }

  &__tab {
    display: none;
  }

  &__tab.active {
    display: block;
  }
}
