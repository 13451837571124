.s-tik-tok {
  padding: 53px 0 57px 0;
  background: $tolpa-orange-light;
  overflow: hidden;

  &__container {
    @include bp-down(medium) {
      &--swiper {
        padding: 0;
      }
    }
  }

  &__row {
    display: flex;
    &--desktop {
      margin: 0;
    }
  }

  &__header {
    margin-bottom: 47px;

    @include bp-down(medium) {
      margin-bottom: 27px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    margin: 0;
    font-family: 'Glypha LT Pro', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
  }

  &__arrows {
    display: none;

    @include bp-down(medium) {
      display: block;
    }
  }

  &__swiper {
    @include bp-down(medium) {
      margin: 0 15px;
      overflow: visible;
    }
  }

  &__arrow {
    background: #fff;
    width: 56px;
    height: 56px;
    border-radius: 70px;
    border: 0;
    margin-left: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__single {
    display: block;
    margin-right: 20px;
    width: 325px;
    padding: 0;
    .css-vptvdy,
    .tiktok-embed {
      min-width: auto !important;
      width: auto !important;
      background: transparent !important;
      border-radius: 10px !important;
    }

    @include bp-down(large) {
      width: 25%;
    }

    @include bp-down(medium) {
      width: 100%;
    }
  }

  // &__image {
  //   width: 100%;
  //   height: auto;
  //   display: block;
  //   object-fit: cover;
  //   object-position: center;

  //   @include bp-down(medium) {
  //     width: 100%;
  //     height: 400px;
  //   }
  // }
}
