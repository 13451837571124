// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Arial' !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  xlarge: 1200px,
  full: 1366px,
);

/* Colors
   ========================================================================== */

$tolpa-peach: #f5e0cc;
$tolpa-orange: #ec792b;
$tolpa-orange-light: #f0c793;
