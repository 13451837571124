.c-footer {
  padding: 83px 0 46px 0;
  background: $tolpa-orange;
  color: #fff;

  @include bp-down(medium) {
    padding: 40px 0 20px 0;
  }

  &__row {
    &--top {
      margin-bottom: 67px;

      @include bp-down(medium) {
        margin-bottom: 30px;
      }
    }

    &--bottom {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__logo {
    width: auto;
    height: 40px;
    margin-bottom: 32px;

    @include bp-down(medium) {
      margin-bottom: 20px;
    }
  }

  &__socials {
    list-style: none;
    padding: 0;
    margin: 0;

    @include bp-down(medium) {
      margin-bottom: 20px;
    }

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 9px;
      }
    }

    a {
      display: block;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__header {
    margin-bottom: 32px;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 26px;
    line-height: 31px;

    @include bp-down(medium) {
      margin-bottom: 20px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;

    @include bp-down(medium) {
      margin-bottom: 30px;
    }
  }

  &__bottom-links {
    text-align: right;

    @include bp-down(medium) {
      margin-bottom: 20px;
      text-align: left;
    }
  }

  &__link {
    &--bottom {
      color: #fff;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
}
