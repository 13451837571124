.s-event {
  // background: linear-gradient(90deg, #eeb562 0%, #fccf91 50.57%, #eeb562 100%);
  background: #f5e0cc;
  height: calc(100vh);
  overflow: hidden;
  min-height: 680px;
  height: 100vh;

  @include bp-down(large) {
    max-height: none;
    height: auto;
  }

  &__box {
    max-width: 1600px;
    position: relative;
    margin: 0 auto;
    height: 100%;
  }

  &__container {
    position: relative;
    height: 100%;

    @include bp-down(large) {
      position: static;
    }
  }

  &__logo {
    width: 103px;
    display: block;
    margin: 0 auto 0 auto;
    padding-top: 24px;
  }

  &__mobile-image {
    display: none;
    @include bp-down(large) {
      display: block;
      width: 100%;
      height: auto;
      margin: 16px 0;
      &--first {
        margin-top: 60px;
      }
    }
  }
  &__content {
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    transform: translateY(calc(-50% - 20px));
    max-width: 485px;
    z-index: 3;

    @include bp-down(large) {
      position: static;
      top: 0;
      transform: none;
      margin: 32px 0 16px 0;
      max-width: none;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-family: 'Palatino';
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    @include bp-down(large) {
      margin-bottom: 12px;
      font-size: 48px;
      line-height: 48px;
    }
  }

  &__step {
    display: inline-block;
    margin-bottom: 32px;
    padding: 2px 8px;
    border-radius: 3px;
    background: #e49c2f;
    font-family: 'Palatino';
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    @include bp-down(large) {
      margin-bottom: 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__form {
    margin-top: 30px;
    a {
      &:hover {
        color: $tolpa-orange;
      }
    }
    .wpcf7-form {
      &.submitting {
        input[type='submit'] {
          animation: pulse 3500ms infinite;
        }
      }
    }
    .wpcf7-form-control-wrap {
      display: block;
      position: relative;
      max-width: 435px;
      @include bp-down(large) {
        text-align: left;
      }
    }
    input[type='email'] {
      width: 375px;
      padding: 12px 20px;
      border: 1px solid #000;
      background: #fff;
      font-size: 16px;
      line-height: 16px;
      @include bp-down(medium) {
        width: 100%;
      }
      &::placeholder {
        color: #909090;
      }
      &:focus {
        outline: none;
      }
    }
    input[type='submit'] {
      @extend .c-btn;
      padding: 15px 24px 11px;
      cursor: pointer;
      @include bp-down(large) {
        width: 100%;
      }
    }
    .wpcf7-acceptance {
      display: block;
    }
    .wpcf7-list-item {
      display: block;
      margin: 20px 0;
      padding-left: 33px;
      position: relative;
    }
    .wpcf7-not-valid-tip,
    .wpcf7-list-item-label {
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    .wpcf7-not-valid-tip {
      max-width: 375px;
      display: block;
      margin-top: 4px;
      color: #000;
      position: absolute;
      top: -20px;
      text-align: right;
      width: 100%;
    }

    .wpcf7-not-valid {
      .wpcf7-list-item-label:after {
        border: 1px solid #e49c2f;
      }
    }

    input[type='email'] {
      &.wpcf7-not-valid {
        border: 1px solid #e49c2f;
      }
    }
    p {
      margin-bottom: 0;
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:checked {
      & ~ .wpcf7-list-item-label {
        &:before {
          display: block;
        }
      }
    }
    .wpcf7-list-item-label {
      cursor: pointer;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: 0;
      }
      &:after {
        width: 24px;
        height: 24px;
        background: #fff;
        border: 1px solid #000;
      }
      &:before {
        top: -2px;
        left: 4px;
        display: none;
        width: 16px;
        height: 16px;
        background: #000;
        border: 1px solid #000;
        z-index: 1;
      }
    }
    .wpcf7 form .wpcf7-response-output {
      margin: 16px 0 0 0;
      padding: 0;
      font-size: 12px;
      line-height: 14px;
      border: 0;
    }
    .cf-acceptance-field {
      .wpcf7-not-valid-tip {
        display: none;
      }
    }
    .cf-email-field {
      margin-bottom: 24px;
    }
    .cf-submit-field {
      display: inline-block;
      margin-top: 33px;
      position: relative;
      @include bp-down(large) {
        text-align: center;
        display: block;
        width: 100%;
      }
    }
    .cf-require-field {
      margin-top: 18px;
      font-size: 12px;
      line-height: 15px;
    }
    .wpcf7-spinner {
      display: none;
    }
  }

  &__image {
    &--desktop {
      position: absolute;
      top: 50%;
      right: 0;
      width: 65%;
      max-width: 950px;
      height: auto;
      max-height: 827px;
      transform: translateY(-50%);
      @include bp-down(large) {
        display: none;
      }
    }
  }
  &__link {
    margin-top: 24px;
  }

  &__floating {
    position: absolute;
    left: 0;
    bottom: 40px;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include bp-down(large) {
      position: static;
      margin: 34px 0;
      justify-content: center;
    }
    &-single {
      display: block;
      color: #000;
      font-size: 14px;
      line-height: 16px;
      text-decoration: underline;
      &:hover {
        color: $tolpa-orange;
      }

      &:not(:first-child) {
        margin-left: 18px;

        @include bp-down(medium) {
          margin-left: 12px;
        }
      }
    }
  }

  &__floating-desc {
    &--desktop {
      position: absolute;
      right: 70px;
      bottom: 40px;
      z-index: 3;
      font-size: 10px;
      line-height: 12.5px;
      &:before {
        content: '*';
        position: absolute;
        top: 0;
        left: -10px;
      }
      @include bp-down(large) {
        display: none;
      }
    }
  }
  &__fruit {
    position: absolute;
    z-index: 0;

    &--fourth {
      top: -135px;
      right: 12px;
      max-width: 267px;
      max-height: 327px;
      transform: rotate(-38deg);
      @media all and (min-width: 1600px) {
        transform: rotate(270deg);
      }
    }
    &--desktop {
      @include bp-down(large) {
        display: none;
      }
    }
    &--mobile {
      display: none;
      @include bp-down(large) {
        display: block;
        top: 0;
        max-width: 207px;
        max-height: 267px;
      }
      @include bp-down(medium) {
        top: -7%;
        right: -5%;
        max-width: 40%;
        max-height: 217px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
