@font-face {
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/arial/ARIAL.TTF');
}

@font-face {
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/arial/ARIALBD.TTF');
}

@font-face {
  font-family: 'Glypha LT Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/glypha-lt-pro/GlyphaLTPro55Rg.TTF');
}

@font-face {
  font-family: 'Palatino';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/palatino-font.ttf');
}
