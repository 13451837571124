.s-hero {
  position: relative;
  background: linear-gradient(90deg, #eeb562 0%, #fccf91 50.57%, #eeb562 100%);
  height: calc(100vh);
  overflow: hidden;
  min-height: 680px;
  max-height: 736px;

  @include bp-down(large) {
    max-height: none;
    height: auto;
  }

  &__container {
    position: relative;
    height: 100%;

    @include bp-down(large) {
      position: static;
    }
  }

  &__logo {
    width: 103px;
    display: block;
    margin: 0 auto 0 auto;
    padding-top: 24px;
  }

  &__content {
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    transform: translateY(calc(-50% - 20px));
    max-width: 369px;
    z-index: 3;

    @include bp-down(large) {
      position: static;
      top: 0;
      transform: none;
      margin: 60px auto;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-family: 'Glypha LT Pro', sans-serif;
    font-size: 42px;
    line-height: 50px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__link {
    margin-top: 24px;
  }

  &__floating {
    position: absolute;
    left: 0;
    bottom: 40px;
    z-index: 3;
    background: $tolpa-peach;
    border-radius: 20px;
    padding: 10px 21px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp-down(medium) {
      position: relative;
      bottom: 10px;
      padding: 10px 15px;
      margin-left: 0;
      margin-right: 0;
      background: rgba($tolpa-peach, 0.65);
    }

    &-single {
      display: block;
      &--text {
        font-size: 12px;
        line-height: 18px;

        @include bp-down(medium) {
          display: none;
        }
      }

      &:not(:first-child) {
        margin-left: 18px;

        @include bp-down(medium) {
          margin-left: 12px;
        }
      }
    }
  }

  &__slider-slide {
    height: 500px;
    position: relative;

    @include bp-down(xlarge) {
      height: 400px;
    }

    @include bp-down(large) {
      height: 100%;
    }
  }

  &__slider {
    margin-top: 20px;
    overflow: visible;

    &-background {
      position: absolute;
      right: 15px;
      bottom: -40%;
      width: 872px;
      height: 872px;
      background: $tolpa-orange;
      border-radius: 50%;

      @include bp-down(xlarge) {
        width: 772px;
        height: 772px;
      }

      @include bp-down(large) {
        margin-top: 100px;
        position: static;
        background: transparent;
        border-radius: 0;
        width: auto;
        height: auto;
      }
    }

    &-image {
      position: relative;
      display: block;
      width: auto;
      height: 500px;
      margin: 0 auto;
      transform: translateX(-5%);
      z-index: 2;

      @include bp-down(xlarge) {
        height: 400px;
      }

      @include bp-down(medium) {
        transform: none;
        width: 100%;
        height: auto;
      }
    }

    &-pagination.swiper-pagination-bullets {
      z-index: 3;
      position: relative;
      bottom: 18px;
      text-align: center;
    }
  }

  &__slide-opinion {
    position: absolute;
    top: -82px;
    left: 50%;
    transform: translateX(-63%);
    max-height: 138px;
  }

  &__slider-slide-heart-first,
  &__slider-slide-heart-second {
    position: absolute;
    width: 120px;
    height: 120px;
    z-index: 3;
  }

  &__slider-slide-heart-first {
    top: calc(50% + 20px);
    left: 100px;

    @include bp-down(large) {
      left: 0;
    }

    @include bp-down(medium) {
      left: -50px;
    }
  }

  &__slider-slide-heart-second {
    top: calc(50% - 120px);
    right: 110px;

    @include bp-down(large) {
      right: 0;
    }

    @include bp-down(medium) {
      right: -50px;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #fff;
    opacity: 1;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: $tolpa-orange-light;
  }

  &__fruit {
    position: absolute;
    z-index: 0;

    &--first {
      max-width: 229px;
      max-height: 275px;
      top: 0;
      left: 0;

      @include bp-down(xlarge) {
        max-width: 179px;
        max-height: 225px;
      }

      @include bp-down(large) {
        max-width: 159px;
        max-height: 205px;
      }

      @include bp-down(medium) {
        top: -40px;
        left: -40px;
      }
    }

    &--second {
      max-width: 329px;
      bottom: 0;
      left: 51px;

      @include bp-down(xlarge) {
        max-width: 279px;
      }

      @include bp-down(large) {
        max-width: 229px;
        left: 31px;
      }

      @include bp-down(medium) {
        left: -80px;
        bottom: -40px;
      }
    }

    &--third {
      top: 40px;
      right: 0;
      max-width: 220px;
      max-height: 283px;

      @include bp-down(large) {
        max-width: 170px;
        max-height: 233px;
      }

      @include bp-down(medium) {
        display: none;
      }
    }

    &--fourth {
      bottom: 21px;
      right: 41px;
      max-width: 277px;
      max-height: 327px;

      @include bp-down(xlarge) {
        max-width: 227px;
        max-height: 277px;
      }

      @include bp-down(large) {
        max-width: 197px;
        max-height: 237px;
        right: 31x;
      }

      @include bp-down(medium) {
        right: -90px;
        bottom: -50px;
      }
    }
  }
}
