.s-products-slider {
  padding: 50px 0 150px 0;
  background: #f5e0cc;

  &__container {
    @include bp-down(medium) {
      padding: 0;
    }
  }

  &__col {
    &--product {
      margin-bottom: 30px;

      @include bp-down(medium) {
        margin-bottom: 15px;
      }
    }
  }

  &__slider {
    padding: 49px 0;
  }

  &__title {
    text-align: center;
    margin-bottom: 32px;
    span {
      display: inline-block;
      padding: 2px 8px;
      border-radius: 3px;
      background: #e49c2f;
      font-family: 'Palatino';
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      text-align: center;
    }
  }

  &__description {
    margin-bottom: 30px;
    text-align: center;
  }
  &__nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-radius: 24px;
  }

  &__nav-tab {
    margin: 0;
    display: inline-block;
    padding: 16px 24px;
    border-right: 1px solid $tolpa-orange;
    border-top: 1px solid $tolpa-orange;
    border-bottom: 1px solid $tolpa-orange;
    color: #000;
    font-size: 16px;
    font-family: 'Glypha LT Pro', sans-serif;
    line-height: 16px;
    text-decoration: none;

    @include bp-down(medium) {
      padding: 16px;
    }

    &:first-child {
      border-left: 1px solid $tolpa-orange;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &:last-child {
      border-left: 0;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  &__nav-tab.active {
    color: #fff;
    background: $tolpa-orange;
  }

  &__tab {
    display: none;
  }

  &__tab.active {
    display: block;
  }
  .c-loop-product {
    background: #fff;
  }
  .c-loop-product__link {
    width: calc(100% - 50px);
  }
  &__slider-box {
    position: relative;
  }
  &__slider {
    position: relative;
  }
  &__arrow {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    cursor: pointer;

    &--prev {
      left: -28px;
      @include bp-down(medium) {
        left: 10px;
      }
    }
    &--next {
      right: -28px;
      @include bp-down(medium) {
        right: 10px;
      }
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #fff;
    opacity: 1;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: $tolpa-orange-light;
  }
  &__nav {
    width: 100%;
    margin-top: 25px;
    text-align: center;
  }
  &__nav-single {
    margin: 0 15px;
    font-size: 14px;
    line-height: 14px;
    text-decoration: underline;
    &:hover {
      color: $tolpa-orange;
    }
  }
}
