/*
  Project: Tołpa
  Author: Daniel Kozina
 */

@import 'settings/_fonts.scss';
@import 'settings/_global.scss';
@import 'tools/_breakpoints.scss';
@import 'generic/_box-sizing.scss';
@import 'generic/_font-face.scss';
@import 'generic/_normalize.scss';
@import 'generic/_reset.scss';
@import 'generic/_shared.scss';
@import 'elements/_blockquote.scss';
@import 'elements/_body.scss';
@import 'elements/_headings.scss';
@import 'elements/_hr.scss';
@import 'elements/_html.scss';
@import 'elements/_images.scss';
@import 'elements/_links.scss';
@import 'elements/_lists.scss';
@import 'elements/_tables.scss';
@import 'vendor/_bootstrap.scss';
@import 'vendor/_swiper.scss';

@import 'components/_btn.scss';
@import 'components/_footer.scss';
@import 'components/_header.scss';
@import 'components/_loop-opinion.scss';
@import 'components/_loop-product.scss';
@import 'components/_single-user.scss';
@import 'sections/_about.scss';
@import 'sections/_event-finish.scss';
@import 'sections/_event.scss';
@import 'sections/_hero.scss';
@import 'sections/_instagram.scss';
@import 'sections/_opinions.scss';
@import 'sections/_power.scss';
@import 'sections/_products-slider.scss';
@import 'sections/_products.scss';
@import 'sections/_tik-tok.scss';
@import 'sections/_white-nav.scss';


.tolpa-chisel {
  #cookie-notice .cookie-notice-container {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 16px;
  }
  #cookie-notice a,
  #cookie-notice .cn-button:not(.cn-button-custom) {
    background-color: transparent !important;
    padding: 0;
    color: $tolpa-orange;
    font-weight: 700;
    text-decoration: underline;
    font-size: 12px;
    line-height: 16px;
  }
  .cn-text-container {
    margin-bottom: 0;
  }
}
